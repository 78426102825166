import { FC } from 'react';
import IconProps from './IconProps';

const CloudUploadIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 0C8.05459 0 6.41718 1.04125 5.35807 2.51139C2.40455 2.54491 0 4.9555 0 7.91667C0 10.8986 2.43478 13.3333 5.41667 13.3333H15.4167C17.938 13.3333 20 11.2713 20 8.75C20 6.25803 17.9809 4.23017 15.4997 4.18294C14.7749 1.78465 12.6267 0 10 0ZM10 1.66667C12.0871 1.66667 13.795 3.18791 14.1097 5.17904L14.2367 5.97982L15.0407 5.87565C15.2268 5.85142 15.3458 5.83333 15.4167 5.83333C17.037 5.83333 18.3333 7.12965 18.3333 8.75C18.3333 10.3704 17.037 11.6667 15.4167 11.6667H10.8333V8.33333H13.3333L10 5L6.66667 8.33333H9.16667V11.6667H5.41667C3.33522 11.6667 1.66667 9.99811 1.66667 7.91667C1.66667 5.83522 3.33522 4.16667 5.41667 4.16667C5.44583 4.16667 5.51372 4.17249 5.625 4.17806L6.13444 4.2041L6.38835 3.76139C7.10842 2.51029 8.44823 1.66667 10 1.66667Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloudUploadIcon;
